<template>
    <div id="about"> 
      <v-row>
          <v-col class="col-xs-12">
            <!-- add your personal bio paragraphs here in this column -->
            
            <h2> ABOUT ME</h2>
            <p>I'm a passionate researcher at the forefront of Mobile Edge Computing (MEC) and Internet of Things (IoT) technologies.
              My research mainly delves into the reliability and trustworthiness of IoT services, ensuring they can seamlessly integrate into our daily lives.</p>
            <p>This website serves as a platform where I share updates about my ongoing research, publications, and collaborations.
              I believe that knowledge is amplified when shared, and through this platform, I aim to engage with fellow researchers, professionals, and anyone curious about the intriguing world of MEC and IoT.</p>
          </v-col>
          <v-col class="col-xs-12">
              <v-img src="../assets/portrait.jpg" max-width="400px" contain >

              </v-img>
          </v-col>
      </v-row>
    </div>
</template>

<script>
export default {
    name : 'about'
}
</script>


<style lang="scss" scoped > 
    #about{
        padding-left: 10%;
        padding-top: 60px;

       h2{  
           text-transform: uppercase;
           font-weight: bold;
           color:var(--accent-color);
           padding-top:15px;
        }
        p {
             padding-block: 10px;
             padding-right: 10px;
             font-weight: 400;
             color: var(--gray);
             font-size: 1.3em;
        }
    }

</style>