<template>
    <v-container fluid class='ma-0 publications' id='publications'>
        <h2>
          Publications
        </h2>
        <v-container fluid class="projects pa-sm-1">
            <v-row align="center"
                justify="center" v-for='(chunk,index) in projectChunks'
                :key ='index'>
                <div v-for='project in chunk' :key='project.img'
                    columns='12'
                    sm='6'>
                    <v-hover v-slot='{hover}'>
                       <v-card                          
                          class='mb-4 ml-1 grey project'  elevation="2"   round  >

                          <v-img :src='project.img'  width='450px'  height='auto' >
                              <v-expand-transition>
                                <div
                                    v-if="hover" style="height: 100%;"
                                    class="d-flex flex-column transition-fast-in-fast-out black darken-4 v-card--reveal  white--text pa-3 justify-center align-center card-reveal" >
                                    <span class=' montserrat description '>
                                        {{ project.description }}
                                    </span>
                                    <v-btn text v-if='project.url'
                                    :href='project.url' target='_blank'
                                    tile width='50%' class='mx-auto my-1 mt-2 white--text'>
                                        Visit
                                    </v-btn>
                                </div>
                              </v-expand-transition>                                   
                          </v-img>
                        </v-card>
                     </v-hover>
                 </div>
            </v-row>
         </v-container>
    </v-container>
</template>

<script>
    export default { 
    name: 'publications',components: {

    } ,
    mounted(){
        // this.$store.dispatch('getProjects')
    },
    data () {
      return {
            
      }
    },
    computed:{
        projectChunks(){
          return this.$store.getters.projectChunks
        }
    }

}    
</script>

<style scoped>

.projects{
    
    margin:auto;
    padding: 5%;
    text-align: center;
    overflow: hidden;
    /* width: 33em;
    min-width: 100vw;   */
    /* height: 30vh; */
    /* max-height: 60vh; */
    /* max-width:95vw; */
}

.project{
  //width: 30vw;
  min-width:25rem;
  height:auto;
  margin:2%;
  margin-left:12px;
  margin-right:12px;
}


.publications>h2{

    //padding-left: 9%;
    text-transform: uppercase;
    font-weight: bold;
    color:var(--accent-color);
    text-align: center;
    padding-top: 80px;
    padding-bottom: 50px;
    font-size:2em;


}/*  */

#publications{
  padding-bottom: 24px
}


.card-reveal{
    opacity: 0.85;
}

#project_name{
 font-size:28px;
}

.tech-icons{
    position: absolute;
    
    justify-content: space-around;
    padding:1px;
    margin:2px;
    bottom: 0;

}
</style>
