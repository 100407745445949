import Vue from 'vue'
import Vuex from 'vuex'

// import {client} from '../axiosClient'
import _ from 'lodash';


Vue.use(Vuex)


export default new Vuex.Store({
    state: {
      Sociallinks:[
          {
              icon:'mdi-linkedin',
              src:'https://www.linkedin.com/in/jiahui-bai-1b168a226/'
          },
          {
              icon:'mdi-github',
              src:'https://github.com/SHVleV9CYWkK'
          },
          {
              icon:'mdi-instagram',
              src:'https://www.instagram.com/huey_bai/'
          }
      ],
      projects: [
          {
                name:"Federated Learning-driven Trust Prediction for Mobile Edge Computing-based IoT Systems",
                description:"We present a federated learning approach for IoT trust prediction in MEC, " +
                    "focusing on low convergence time and data heterogeneity. Our method, using Federated " +
                    "Expectation-Maximization, outperforms existing techniques in efficiency and accuracy on real IoT datasets.",
                img:"https://res.cloudinary.com/dcpa4dy0j/image/upload/v1692502592/j31qxdkkheaiooa81oov.png",
                url:"#"
            },
        ]

      

  },
    mutations:{
        // addProjects(state,projects){
        //     state.projects = projects
        // }
    },
    getters:{
        projectChunks(state){
            return _.chunk(state.projects,2)
        }
    },
    actions:{
        // async getProjects({commit}){
        //     //let url='http://localhost:8000/projects'
        //     let res=await client.get('projects');
        //     console.log(JSON.stringify(res));
        //     commit('addProjects',res.data);

        // }

    }



})
